import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import HeroSection from "./components/HeroSection";
import ContactUs from "./components/ContactUs";
import Modal from "./components/Modal";
import Merchant from "./components/Merchant";
import Agent from "./components/Agent";
import UserManual from "./components/UserManual";
import AboutSection from './components/AboutSection'; 
import Countdown from "./components/Countdown";
import CardsSection from "./components/CardsSection";
import PageNotFound from "./components/PageNotFound";
import "./App.css";

function App() {
  const [publicKey, setPublicKey] = useState(null);
  const [modalMessage, setModalMessage] = useState("");
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (window.solana && window.solana.isPhantom) {
      // Auto-connect to the wallet if already connected
      window.solana.connect({ onlyIfTrusted: true })
        .then(({ publicKey }) => {
          setPublicKey(publicKey.toString());
        })
        .catch(() => {
          // Handle the case where the user is not connected
        });
    }
  }, []);

  const connectWallet = async () => {
    if (window.solana && window.solana.isPhantom) {
      try {
        const { publicKey } = await window.solana.connect();
        setPublicKey(publicKey.toString());
      } catch (error) {
        setModalMessage("Failed to connect wallet");
        setShowModal(true);
      }
    } else {
      setModalMessage("Please install Phantom Wallet!");
      setShowModal(true);
    }
  };

  const disconnectWallet = async () => {
    try {
      await window.solana.disconnect();
      setPublicKey(null);
    } catch (error) {
      setModalMessage("Failed to disconnect wallet");
      setShowModal(true);
    }
  };

  return (
    <div className={`App ${showModal ? 'blurred' : ''}`}>
      <Navbar
        connectWallet={connectWallet}
        disconnectWallet={disconnectWallet}
        account={publicKey}
      />

      <Routes>
        <Route
          path="/"
          element={
            <HeroSection
              publicKey={publicKey}
              connectWallet={connectWallet}
            />
          }
        />
        <Route path="/merchant" element={<Merchant />} />
        <Route path="/agent" element={<Agent />} />
        <Route path="/user-manual" element={<UserManual />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <AboutSection /> 
      <Countdown />
      <CardsSection />
      <ContactUs />
      <Modal
        show={showModal}
        onClose={() => setShowModal(false)}
        message={modalMessage}
      />
    </div>
  );
}

export default App;
