import React from 'react';
import './Merchant.css';

const Merchant = () => {
  return (
    <section className="merchant-section">
      <div className="merchant-container">
        <h1>Taka Coin Merchant Solutions</h1>
        <p>
          Welcome to the Taka Coin Merchant Portal! Our platform is designed to
          help merchants and businesses of all sizes accept Taka Coin as a form
          of payment. Whether you run a small retail store or a large
          e-commerce business, integrating Taka Coin will provide your
          customers with a secure, fast, and efficient payment method.
        </p>
        <h2>Benefits of Becoming a Taka Coin Merchant:</h2>
        <ul>
          <li>
            <strong>Global Reach:</strong> Tap into the growing cryptocurrency market and attract customers from around the world.
          </li>
          <li>
            <strong>Low Transaction Fees:</strong> Save on traditional payment processing fees and enjoy low-cost transactions.
          </li>
          <li>
            <strong>Secure and Transparent:</strong> All transactions are secured on the blockchain, providing transparency and reducing fraud.
          </li>
          <li>
            <strong>Instant Payments:</strong> Get paid faster with near-instant cryptocurrency transfers.
          </li>
        </ul>
        <h2>How to Start:</h2>
        <ol>
          <li><strong>Sign up</strong> as a merchant on our platform.</li>
          <li><strong>Integrate</strong> Taka Coin into your payment system with our easy-to-use API.</li>
          <li>Start <strong>accepting</strong> payments from your customers in Taka Coin.</li>
        </ol>
      </div>
    </section>
  );
};

export default Merchant;
