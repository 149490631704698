import React from 'react';
import './UserManual.css';

const UserManual = () => {
  return (
    <section className="user-manual">
      <div className="container">
        <h1>User Manual</h1>
        <p>Welcome to Taka Coin! This guide will walk you through the process of requesting tokens, checking the Initial Coin Offering (ICO) countdown, and using our platform.</p>
        
        <h2>Getting Started with Taka Coin</h2>
        <p>To begin using Taka Coin and participate in the ICO, follow these steps:</p>
        
        <ol>
          <li>
            <strong>Connect Your Wallet:</strong> 
            <p>On the homepage, click the <em>Get Started</em> button. This will prompt you to connect your wallet using MetaMask. If you haven't installed MetaMask, a message will appear asking you to do so.</p>
          </li>

          <li>
            <strong>Switch to the Correct Network:</strong> 
            <p>Once connected, ensure you are on the <em>Skale Testnet</em> network. If you are not, the platform will automatically add the network for you. Simply approve the network switch when prompted.</p>
          </li>

          <li>
            <strong>Request Tokens:</strong> 
            <p>After connecting your wallet, enter the amount of Taka Coin you wish to request. The system will fetch the equivalent USDT amount you need to pay from Binance. Once the USDT price is fetched, click on <em>Request Tokens</em> to proceed.</p>
          </li>

          <li>
            <strong>Make the Payment:</strong> 
            <p>Once you click <em>Request Tokens</em>, a QR code will appear with payment details, including a <em>Binance ID</em> and the amount of USDT to pay. Use Binance to scan the QR code and complete the payment.</p>
          </li>

          <li>
            <strong>Verify the Payment:</strong> 
            <p>After making the payment, select the checkbox labeled <em>I have paid</em>. This will enable a transaction ID field. Enter the transaction ID from your Binance payment and click <em>Verify</em> to confirm.</p>
          </li>

          <li>
            <strong>Check ICO Countdown:</strong> 
            <p>Scroll down the page to find the countdown for our Initial Coin Offering (ICO). The countdown shows the number of days, hours, minutes, and seconds remaining until the Taka Token ICO starts. Stay tuned and be ready to participate!</p>
          </li>
        </ol>

        <h2>Understanding the ICO Countdown</h2>
        <p>The countdown timer on the homepage gives you a real-time update on when the Taka Token ICO will begin. Here’s what you need to know:</p>
        
        <ul>
          <li><strong>Days:</strong> Displays how many days remain before the ICO launch.</li>
          <li><strong>Hours:</strong> Shows the number of hours left before the ICO starts.</li>
          <li><strong>Minutes:</strong> Keeps track of the remaining minutes leading up to the ICO.</li>
          <li><strong>Seconds:</strong> Provides a countdown of the seconds until the ICO begins.</li>
        </ul>

        <p>The countdown section is highlighted with a glowing effect to ensure it's easy to spot. Keep an eye on it to know exactly when you can participate!</p>

        <h2>FAQ</h2>
        <p>Here are some common questions you may have while using our platform:</p>
        
        <ul>
          <li><strong>How do I change the network?</strong> 
            <p>The platform will automatically switch you to the correct network. If you need to switch manually, open MetaMask, go to Networks, and select <em>Skale Testnet</em>.</p>
          </li>
          <li><strong>What if I can’t find my transaction ID?</strong> 
            <p>Your transaction ID can be found in the history of your Binance account. Navigate to your recent transactions and find the one related to your Taka Coin purchase.</p>
          </li>
          <li><strong>What happens if my payment is not verified?</strong> 
            <p>If your payment is not verified, double-check your transaction ID and make sure you've completed the payment to the correct Binance ID.</p>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default UserManual;
