import React from 'react';
import Countdown from 'react-countdown';
import './Countdown.css';
const renderer = ({ days, hours, minutes, seconds }) => {
  return (
    <div className="countdown-box">
      <div className="countdown-item">
        <h1>{days}</h1>
        <p>Days</p>
      </div>
      <div className="countdown-item">
        <h1>{hours}</h1>
        <p>Hours</p>
      </div>
      <div className="countdown-item">
        <h1>{minutes}</h1>
        <p>Minutes</p>
      </div>
      <div className="countdown-item">
        <h1>{seconds}</h1>
        <p>Seconds</p>
      </div>
    </div>
  );
};

const CountdownSection = () => {
  // Set a future ICO end date (e.g., 6 months from today)
  const icoDate = new Date("2025-03-18T00:00:00");
  icoDate.setMonth(icoDate.getMonth());

  return (
    <section className="countdown-section">
      <div className="ico-text">
        <h2>$Taka Coin ICO</h2>
        <p>Our Taka Token will be listed soon! Stay tuned for the Initial Coin Offering (ICO) in just:</p>
      </div>
      <Countdown date={icoDate} renderer={renderer} />
    </section>
  );
};

export default CountdownSection;
