import React from 'react';
import './AboutSection.css'; // Create this CSS file to style the section

const AboutSection = () => {
  return (
    <section className="about-section">
      <div className="content-container">
        <h2 className="section-title">About Taka Coin</h2>
        <p className="section-paragraph">
          The global Bangladeshi diaspora faces unique financial challenges that traditional systems often overlook. Whether managing finances abroad, making transactions while travelling, or sending money home, these groups encounter high fees, limited access, and inefficient services. Taka Coin addresses these challenges by providing a tailored solution for:
        </p>
        <h3>Non-Residential Bangladeshis (NRBs)</h3>
        <p className="section-paragraph">
          NRBs face challenges in managing finances abroad due to reliance on traditional banking systems, which often come with high fees and limited accessibility. Taka Coin offers a convenient way to manage their finances, make transactions, and invest without the need for traditional banks.
        </p>
        <h3>Bangladeshi Tourists</h3>
        <p className="section-paragraph">
          Tourists from Bangladesh require a seamless and cost-effective method to handle transactions and currency exchanges while travelling. Taka Coin provides a secure and easy-to-use platform for these needs, potentially offering features like travel insurance or local discounts.
        </p>
        <h3>Bangladeshi Workers Abroad</h3>
        <p className="section-paragraph">
          Workers employed in foreign countries need a reliable and affordable method to exchange money in foreign countries. Taka Coin facilitates this by providing a low-cost, secure platform for money exchange along with other financial services to manage earnings and expenses.
        </p>
        <p className="section-paragraph">
          Taka Coin is uniquely positioned to address these needs, offering a digital currency solution that is secure, transparent, and tailored to the Bangladeshi community worldwide.
        </p>
      </div>
    </section>
  );
};

export default AboutSection;
