import React from 'react';
import './Modal.css'; // Assuming you separate styles for the Modal

const Modal = ({ show, onClose, message }) => {
    if (!show) return null;

    return (
        <div className="modal show">
            <div className="modal-content">
                <p>{message}</p>
                <button onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

export default Modal;
