import React, { useState } from 'react';
import './ContactUs.css';
// import emailjs from 'emailjs-com';

const ContactUs = () => {
    // const [formData, setFormData] = useState({
    //     email: '',
    //     topic: ''
    // });
    // const [message, setMessage] = useState('');
    // const [isLoading, setIsLoading] = useState(false);

    // const handleChange = (e) => {
    //     setFormData({
    //         ...formData,
    //         [e.target.name]: e.target.value
    //     });
    // };

    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     setIsLoading(true);

    //     // Using emailjs-com to send the email
    //     emailjs.send('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', formData, 'YOUR_USER_ID')
    //         .then((response) => {
    //             console.log('SUCCESS!', response.status, response.text);
    //             setMessage('Message sent successfully!');
    //             setFormData({ email: '', topic: '' });
    //         })
    //         .catch((err) => {
    //             console.error('FAILED...', err);
    //             setMessage('Failed to send message. Please try again later.');
    //         })
    //         .finally(() => setIsLoading(false));
    // };

    return (
        <section id="contact-us" className="contact-us">
            <div className="contact-content">
                <h2>Need Help !!!</h2>
                <p>Contact at: ashequr.marketing@gmail.com</p>
                {/* <p>Let’s start earning through crypto trading.</p> */}
                {/* <div className="social-icons">
                    <i className="fab fa-discord"></i>
                    <i className="fab fa-reddit"></i>
                    <i className="fab fa-instagram"></i>
                </div> */}
            </div>
            {/* <div className="contact-form">
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Email Address</label>
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder="Enter Email Address"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Discussion Topic</label>
                        <textarea
                            name="topic"
                            value={formData.topic}
                            onChange={handleChange}
                            placeholder="Enter The Discussion Topic"
                            required
                        ></textarea>
                    </div>
                    <button type="submit" className="send-message-btn" disabled={isLoading}>
                        {isLoading ? 'Sending...' : 'Send Message'}
                    </button>
                </form>
                {message && <p>{message}</p>}
            </div> */}
        </section>
    );
};

export default ContactUs;
