import React from 'react';
import './Agent.css';

const Agent = () => {
  return (
    <section className="agent-section">
      <div className="agent-container">
        <h1>Taka Coin Agent Program</h1>
        <p>
          Join our Taka Coin Agent Program and become a key part of our growing
          network! As a Taka Coin Agent, you will have the opportunity to help
          others access and trade Taka Coin while earning commissions for your
          services.
        </p>
        <h2>What is a Taka Coin Agent?</h2>
        <p>
          A Taka Coin Agent is an individual or business that facilitates the
          exchange of Taka Coin for fiat currencies (or vice versa) in various
          regions. Agents play a crucial role in promoting the adoption of Taka
          Coin and providing liquidity in the market.
        </p>
        <h2>Benefits of Becoming a Taka Coin Agent:</h2>
        <ul>
          <li><strong>Earn Commissions:</strong> Gain a percentage of every transaction you facilitate.</li>
          <li><strong>Flexible Work:</strong> Operate from anywhere, at any time, with no strict working hours.</li>
          <li>
            <strong>Support and Resources:</strong> Receive full support from the Taka Coin team, including marketing materials, technical support, and ongoing training.
          </li>
          <li><strong>Expand Your Network:</strong> Join a community of like-minded individuals passionate about cryptocurrency and blockchain technology.</li>
        </ul>
        <h2>How to Start:</h2>
        <ol>
          <li><strong>Apply</strong> to become an agent through our portal.</li>
          <li><strong>Receive training</strong> and support from the Taka Coin team.</li>
          <li>Start <strong>facilitating</strong> transactions and earning commissions.</li>
        </ol>
      </div>
    </section>
  );
};

export default Agent;
