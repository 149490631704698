import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import "./Navbar.css";
import logo from "../image/logo.png"; // Import your logo image here

const Navbar = ({ connectWallet, disconnectWallet, account }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  // Function to toggle the mobile menu
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header>
      <nav>
        {/* Logo Section */}
        <div className="logo-container">
          <img src={logo} alt="Taka Coin Logo" className="navbar-logo" /> {/* Logo added */}
          <div className="logo-text">Taka Coin</div> {/* Logo text */}
        </div>

        {/* Navigation Links */}
        <ul className={`nav-links ${menuOpen ? "show" : ""}`}>
          <li>
            <Link to="/" onClick={toggleMenu}>Home</Link>
          </li>
          <li>
            <Link to="/merchant" onClick={toggleMenu}>Merchant</Link>
          </li>
          <li>
            <Link to="/agent" onClick={toggleMenu}>Agent</Link>
          </li>
          <li>
            <Link to="/user-manual" onClick={toggleMenu}>User Manual</Link>
          </li>
          <li>
            <ScrollLink to="contact-us" smooth={true} duration={500} offset={-70} onClick={toggleMenu}>
              Support
            </ScrollLink>
          </li>
          <li>
            <a
              href="https://taka-coin.gitbook.io/taka-coin"
              target="_blank"
              rel="noopener noreferrer"
              onClick={toggleMenu}
            >
              White Paper
            </a>
          </li>
        </ul>

        {/* Wallet Connection Button */}
        <button className="sign-up-btn" onClick={account ? disconnectWallet : connectWallet}>
          {account ? "Disconnect" : "Connect Wallet"}
        </button>

        {/* Hamburger Menu */}
        <div className="hamburger-menu" onClick={toggleMenu}>
          <div className="hamburger-bar"></div>
          <div className="hamburger-bar"></div>
          <div className="hamburger-bar"></div>
        </div>

        {/* Close Menu Button */}
        {menuOpen && <span className="close-menu" onClick={toggleMenu}>&times;</span>}
      </nav>
    </header>
  );
};

export default Navbar;
