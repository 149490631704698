import React from "react";
import "./CardsSection.css";

const CardsSection = () => {
  const cardData = [
    {
      title: "Deposit $50",
      description: "Deposit $50 and get 20 extra coins",
      buttonText: "Deposit Now",
    },
    {
      title: "Deposit $100",
      description: "Deposit $100 get 50 extra coins",
      buttonText: "Deposit Now",
    },
    {
      title: "Deposit $1000",
      description: "Deposit $1000 and get 1000 extra coins",
      buttonText: "Deposit Now",
    },
  ];

  return (
    <section className="cards-section">
      <h2>Exclusive Deposit Offers</h2>
      <div className="cards-container">
        {cardData.map((card, index) => (
          <div className="card" key={index}>
            <div className="card-content">
              <h3>{card.title}</h3>
              <p>{card.description}</p>
              <button className="deposit-btn">{card.buttonText}</button>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default CardsSection;
