import React, { useEffect, useState } from "react";
import axios from "axios";
import { hexToNumberString } from "web3-utils";

import { Connection, clusterApiUrl } from "@solana/web3.js";

import "./HeroSection.css";
import qrCodeImage from "../../src/image/qr.png";
import gifImage from "../../src/image/GIF.gif";

// Environment variables for sensitive data
const USDT_CONTRACT_ADDRESS_AVA =
  process.env.REACT_APP_USDT_CONTRACT_ADDRESS_AVA;
const CCHAIN_RPC_URL = "https://api.avax.network/ext/bc/C/rpc";

const HeroSection = ({ publicKey, connectWallet }) => {
  const [requestAmount, setRequestAmount] = useState("");
  const [usdtAmount, setUsdtAmount] = useState(null);
  const [loading, setLoading] = useState(false);
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const [showQrCode, setShowQrCode] = useState(false);
  const [isPaymentVerified, setIsPaymentVerified] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [modalMessage, setModalMessage] = useState(null);
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [connection, setConnection] = useState(null);

  useEffect(() => {
    // Initialize Solana connection
    const conn = new Connection(clusterApiUrl("mainnet-beta"), "confirmed");
    setConnection(conn);
  }, []);

  const fetchUsdtEquivalent = async (amount) => {
    setLoading(true);
    setTimeout(() => {
      const usdtEquivalent = (amount * 0.011).toFixed(2); // Example conversion rate
      setUsdtAmount(usdtEquivalent);
      setLoading(false);
    }, 2000);
  };

  const handleRequestTokens = () => {
    if (publicKey && requestAmount > 0 && usdtAmount) {
      setShowQrCode(true);
    } else {
      alert("Please connect your wallet and enter a valid amount.");
    }
  };

  const handleAmountChange = (e) => {
    const amount = e.target.value;
    setRequestAmount(amount);
    setUsdtAmount(null);

    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    const newTimeout = setTimeout(() => {
      if (amount > 0) {
        fetchUsdtEquivalent(amount);
      }
    }, 2000);

    setDebounceTimeout(newTimeout);
  };

  const verifyTransaction = async () => {
    try {
      setLoading(true);
      // if (!web3) {
      //   throw new Error("Web3 not initialized");
      // }

      if (transactionHistory.includes(transactionId)) {
        setModalMessage("Transaction already used.");
        return;
      }

      const response = await axios.post(CCHAIN_RPC_URL, {
        jsonrpc: "2.0",
        method: "eth_getTransactionReceipt",
        params: [transactionId],
        id: 1,
      });

      const transactionDetails = response.data.result;

      if (!transactionDetails) {
        setModalMessage("Invalid transaction.");
        return;
      }

      const tokenTransferLog = transactionDetails.logs.find(
        (log) =>
          log.address.toLowerCase() ===
            USDT_CONTRACT_ADDRESS_AVA.toLowerCase() &&
          log.topics[0] ===
            "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef" // ERC-20 Transfer event signature
      );

      if (!tokenTransferLog) {
        setModalMessage("Token transfer not found in transaction.");
        return;
      }

      const amountTransferredHex = tokenTransferLog.data;
      const amountTransferred = hexToNumberString(amountTransferredHex);
      const amountTransferredInUSDT = amountTransferred / 10 ** 6; // Adjust for 6 decimals
      const amountMatches =
        Number(amountTransferredInUSDT.toFixed(6)) >= Number(usdtAmount);

      if (!amountMatches) {
        setModalMessage(
          `Transaction validation failed: Amount mismatch. Transferred: ${amountTransferredInUSDT} USDT`
        );
        return;
      }

      setTransactionHistory([...transactionHistory, transactionId]);
      transferTokens();
    } catch (error) {
      console.error("Error verifying transaction:", error);
      setModalMessage("Transaction verification failed.");
    } finally {
      setLoading(false); // Set loading state to false when done
    }
  };

  const transferTokens = async () => {
    try {
      setLoading(true);

      const response = await axios.post(
        "https://api.takacoin.live/transfer-tokens",
        {
          recipientPublicKey: publicKey,
          amount: requestAmount,
        }
      );

      if (response.data.success) {
        setModalMessage("Tokens transferred successfully!");
        console.log("Transaction signature:", response.data.signature);
      } else {
        setModalMessage("Failed to transfer tokens: " + response.data.error);
      }
    } catch (error) {
      console.error("Error transferring tokens:", error);
      setModalMessage("Failed to transfer tokens.");
    } finally {
      setLoading(false);
    }
  };

  const closeModal = () => {
    setModalMessage(null);
  };

  useEffect(() => {
    setIsPaymentVerified(false);
  }, [usdtAmount]);

  return (
    <>
      <section className="hero">
        <div className="hero-container">
          <div className="hero-gif">
            <img src={gifImage} alt="GIF animation" />
          </div>
          <div className="hero-text">
            <h1>Let's Buy Taka Coin Now!</h1>
            <p>
              Taka Coin is a pioneering digital currency designed to empower
              Bangladeshis worldwide, whether they are non-residential
              Bangladeshis (NRBs), tourists, or workers abroad.
            </p>
            <div className="wallet-info">
              {publicKey && !showQrCode && (
                <>
                  <p>Connected Wallet: {publicKey}</p>
                  <input
                    type="number"
                    placeholder="Enter amount"
                    value={requestAmount}
                    onChange={handleAmountChange}
                    disabled={loading}
                  />
                  {usdtAmount && (
                    <p>
                      You have to pay:{" "}
                      <span style={{ color: "#00ff9d" }}>
                        {+usdtAmount + 0.2}
                      </span>{" "}
                      USDT from Binance
                    </p>
                  )}
                </>
              )}
              {showQrCode && (
                <div className="payment-section">
                  <img src={qrCodeImage} alt="QR Code" className="qr-code" />
                  <div className="payment-details">
                    <p>
                      Pay ID:{" "}
                      <span className="highlight">
                        0x09aFfC8bD469B20F1216B0392746460CDb920693
                      </span>
                    </p>
                    <p>
                      Network: <span className="highlight">AVAX C-Chain</span>
                    </p>
                    <p>
                      Pay Amount:{" "}
                      <span style={{ color: "#00ff9d" }}>
                        {+usdtAmount + 0.2}
                      </span>{" "}
                      USDT
                    </p>
                    <button
                      className="change-amount-btn"
                      onClick={() => setShowQrCode(false)}
                    >
                      Change Amount
                    </button>
                    <label>
                      <input
                        type="checkbox"
                        onChange={() =>
                          setIsPaymentVerified(!isPaymentVerified)
                        }
                      />
                      I have paid
                    </label>
                    {isPaymentVerified && (
                      <div className="verification-section">
                        <input
                          type="text"
                          placeholder="Enter transaction ID"
                          value={transactionId}
                          onChange={(e) => setTransactionId(e.target.value)}
                        />
                        <button onClick={verifyTransaction} disabled={loading}>
                          {loading ? "Verifying..." : "Verify"}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            {!showQrCode && (
              <div className="hero-buttons">
                <button
                  className="get-started-btn"
                  onClick={() => {
                    if (!publicKey) {
                      connectWallet();
                    } else {
                      handleRequestTokens();
                    }
                  }}
                  disabled={!usdtAmount && publicKey}
                >
                  {loading
                    ? "Fetching Price..."
                    : publicKey
                    ? "Request Tokens"
                    : "Buy Taka Coin"}
                </button>
              </div>
            )}
          </div>
        </div>
      </section>
      {modalMessage && (
        <div className="modal">
          <p>{modalMessage}</p>
          <button onClick={closeModal} className="close-modal-btn">
            Close
          </button>
        </div>
      )}
    </>
  );
};

export default HeroSection;
