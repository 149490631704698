import React from 'react';
import './UserManual.css';

const UserManual = () => {
  return (
    <div className="user-manual">
      <h1 className="manual-title">User Manual for Requesting Taka Coin</h1>

      <section className="manual-step">
        <h2>Step 1: Make a Payment to Our Binance Account</h2>
        <p>
          To request Taka Coin, you first need to transfer a fixed amount of tokens to our Binance account. The payment details are as follows:
        </p>
        <div className="payment-details">
          <p><strong>Binance Account:</strong> [Your Binance Account ID]</p>
          <p><strong>Fixed Amount:</strong> [Insert Fixed Token Amount]</p>
          <p><strong>Token to Transfer:</strong> [Token Name, e.g., USDT, BNB]</p>
        </div>
        <p>Please ensure that you double-check the payment details before proceeding.</p>
      </section>

      <section className="manual-step">
        <h2>Step 2: Submit Your Payment Confirmation</h2>
        <p>Once you have completed the transfer, you will need to submit proof of your payment. Follow these steps:</p>
        <ul>
          <li>Take a screenshot of the payment confirmation from Binance.</li>
          <li>Fill in the payment confirmation form on our website and upload the screenshot.</li>
          <li>Provide your wallet address where you want to receive the Taka Coin tokens.</li>
        </ul>
      </section>

      <section className="manual-step">
        <h2>Step 3: Receive Taka Coin Tokens</h2>
        <p>
          After we verify your payment, the equivalent amount of Taka Coin tokens will be transferred to the wallet address you provided.
          The process may take a few minutes to complete.
        </p>
      </section>

      <section className="faq-section">
        <h2>FAQs</h2>
        <ul>
          <li><strong>How long does it take to receive the tokens?</strong> Tokens will be sent within 5-10 minutes after payment confirmation.</li>
          <li><strong>What if I made an incorrect payment?</strong> Please contact our support team immediately to resolve the issue.</li>
        </ul>
      </section>

      <section className="contact-section">
        <h2>Contact Us</h2>
        <p>If you encounter any issues or need further assistance, feel free to reach out to our support team:</p>
        <p><strong>Email:</strong> support@taka-coin.com</p>
        <p><strong>Phone:</strong> +123-456-7890</p>
      </section>
    </div>
  );
};

export default UserManual;
